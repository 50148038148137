import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { toastOptions } from '../../../api/functions';
import { bmsAPI } from '../../../api/modules/bmsAPI';
import { store } from '../../store.ts';

export const bmsSliceServices = createSlice({
    name: 'bmsServers',

    initialState: {
        bms_servers_list: [],
        bms_servers_state: [],
        bms_error_servers_arr: [],
        bms_data_type_time_intervals: [],
        bms_data_types_from_ops_server: [],
        bms_server_error: false,
        bms_servers_fetching: false,
        bmsAlarms: {},
    },

    reducers: {
        add_bms_venueName_in_server: (state, action) => {
            state.bms_servers_list = state.bms_servers_list.map(server =>
                action.payload.servers.indexOf(server.id) >= 0 && !server.objects.includes(action.payload.title)
                    ? { ...server, objects: `${server?.objects}${server.objects ? ', ' : ''}${action.payload.title}` }
                    : action.payload.servers.indexOf(server.id) >= 0 && server.objects.includes(action.payload.title)
                      ? server
                      : {
                            ...server,
                            objects: server.objects
                                .replace(
                                    new RegExp(
                                        `${action.payload.title},?\\s*|,\\s*${action.payload.title}(?!.*, ${action.payload.title})|,\\s*${action.payload.title}(?=,|$)|^${action.payload.title}(?=,|$)`,
                                        'g'
                                    ),
                                    ''
                                )
                                .replace(/,+/g, ',')
                                .replace(/^,|,$/g, ''),
                        }
            );
        },
        remove_bms_venue_from_server: (state, action) => {
            state.bms_servers_list = state.bms_servers_list.map(server =>
                server.objects.includes(action.payload)
                    ? {
                          ...server,
                          objects: server.objects.replace(new RegExp('(,\\s*)?' + action.payload + '(,)?', 'g'), ''),
                      }
                    : server
            );
        },
        set_bms_servers_list: (state, action) => {
            state.bms_servers_list = action.payload;
        },
        add_bms_server: (state, action) => {
            state.bms_servers_list = [...state.bms_servers_list, action.payload];
        },
        edit_bms_server: (state, action) => {
            state.bms_servers_list = state.bms_servers_list.map(server =>
                server.id === action.payload.id ? action.payload : server
            );
        },
        remove_bms_server: (state, action) => {
            state.bms_servers_list = state.bms_servers_list.filter(server => server.id !== action.payload);
        },
        set_bms_servers_state: (state, action) => {
            state.bms_servers_state = action.payload;
        },
        set_bms_error_servers_arr: (state, action) => {
            state.bms_error_servers_arr = action.payload;
        },
        set_bms_server_error: (state, action) => {
            state.bms_server_error = action.payload;
        },
        set_servers_fetching: (state, action) => {
            state.bms_servers_fetching = action.payload;
        },
        set_bms_data_type_time_intervals: (state, action) => {
            state.bms_data_type_time_intervals = action.payload;
        },
        set_bms_data_types_from_ops_server: (state, action) => {
            state.bms_data_types_from_ops_server = action.payload;
        },
        setBmsAlarms: (state, action) => {
            state.bmsAlarms = action.payload;
        },
    },
});

export const {
    add_bms_server,
    edit_bms_server,
    remove_bms_server,
    set_bms_server_error,
    set_bms_servers_list,
    set_servers_fetching,
    set_bms_servers_state,
    set_bms_error_servers_arr,
    add_bms_venueName_in_server,
    remove_bms_venue_from_server,
    set_bms_data_type_time_intervals,
    set_bms_data_types_from_ops_server,
    setBmsAlarms,
} = bmsSliceServices.actions;

// Получение списка серверов
export const getBMSServersList = () => async dispatch => {
    dispatch(set_servers_fetching(true));

    const username = store.getState().login.username;
    const auth_key_calendar = store.getState().login.auth_key_calendar;
    const lang = store.getState().login.lang;

    try {
        const response = await bmsAPI.getBMSServers.fetch(username, auth_key_calendar, lang);

        if (response.status === 200) {
            const res = await response.json();

            if (res.code === 0) {
                const data = res.data.map(server => {
                    return {
                        state_error: server.state_error,
                        title: server.title,
                        objects: server.objects || '',
                        id: server.id,
                        cur_state: server.cur_state,
                        is_enabled: server.is_enabled,
                        data: JSON.parse(server.data),
                    };
                });

                dispatch(set_bms_servers_list(data));
            } else {
                toast.error(res.msg, toastOptions);
            }
        }
    } catch (e) {
        toast.error(`Method getBMSServersList \n ${e}`, toastOptions);
    } finally {
        dispatch(set_servers_fetching(false));
    }
};

// Статистика работы серверов
export const getBMSServersStats = () => async dispatch => {
    const username = store.getState().login.username;
    const auth_key_calendar = store.getState().login.auth_key_calendar;

    try {
        const response = await bmsAPI.getBMSServersStats.fetch(username, auth_key_calendar);

        if (response.status === 200) {
            const res = await response.json();

            if (res.code === 0) {
                if (res.data.servers.length) {
                    dispatch(set_bms_servers_state(res.data.servers));

                    const servers_error = res.data.servers.filter(server => server.state_error);

                    if (servers_error.length > 0) {
                        dispatch(set_bms_server_error(true));
                        dispatch(set_bms_error_servers_arr(servers_error));

                        return servers_error;
                    } else {
                        dispatch(set_bms_server_error(false));
                        dispatch(set_bms_error_servers_arr([]));
                    }
                } else {
                    dispatch(set_bms_server_error(false));
                    dispatch(set_bms_error_servers_arr([]));
                }

                dispatch(
                    setBmsAlarms({ alarms: res.data.alarms, blink: res.data.blink, devProblems: res.data.devproblems })
                );
            } else {
                toast.error(res.msg, toastOptions);
            }
        }
    } catch (e) {
        toast.error(`Method getBMSServersStats \n ${e}`, toastOptions);
    }
};

// Статистика работы серверов long polling
export const getBMSServersStatsLP = () => async dispatch => {
    const state = store.getState();

    try {
        const response = await bmsAPI.getBMSServersStatsLP.fetch(state.login.username, state.login.auth_key_calendar);

        if (response.status === 504 || response.status === 204) {
            await dispatch(getBMSServersStatsLP());
        } else if (response.status !== 200) {
            console.error(response.statusText);

            await new Promise(resolve => setTimeout(resolve, 5000));

            await dispatch(getBMSServersStatsLP());
        } else if (response.status === 200) {
            const res = await response.json();

            if (res.code === 0) {
                if (res.data.servers.length) {
                    dispatch(set_bms_servers_state(res.data.servers));

                    const servers_error = res.data.servers.filter(server => server.state_error);

                    if (servers_error.length > 0) {
                        dispatch(set_bms_server_error(true));
                        dispatch(set_bms_error_servers_arr(servers_error));

                        return servers_error;
                    } else {
                        dispatch(set_bms_server_error(false));
                        dispatch(set_bms_error_servers_arr([]));
                    }
                } else {
                    dispatch(set_bms_server_error(false));
                    dispatch(set_bms_error_servers_arr([]));
                }

                dispatch(
                    setBmsAlarms({ alarms: res.data.alarms, blink: res.data.blink, devProblems: res.data.devproblems })
                );
            } else {
                toast.error(res.msg, toastOptions);
            }

            await dispatch(getBMSServersStatsLP());
        }
    } catch (e) {
        console.error(`Method getBMSServersStatsLP \n ${e}`);
    }
};

// Получение интервалов для настройки типов данных OPS сервера
export const getBMSTypesTimeInterval = () => async dispatch => {
    try {
        const response = await bmsAPI.getBMSTypesTimeInterval.fetch();
        if (response.status === 200) {
            const result = await response.json();

            if (result.code === 0) {
                dispatch(set_bms_data_type_time_intervals(result.data));
            }
        }
    } catch (e) {
        toast.error(`Method getBMSTypesTimeInterval \n ${e}`, toastOptions);
    }
};

// Получение типов данных OPS сервера
export const getBMSTypesFromOpsServer = () => async dispatch => {
    try {
        const response = await bmsAPI.getBMSTypesFromOpsServer.fetch();
        if (response.status === 200) {
            const result = await response.json();

            if (result.code === 0) {
                const dataTypes = result.data.map(type => ({
                    id: type.value,
                    type: type.label,
                    period: { value: 1, label: '1 мин' },
                    typeOptions: 'period',
                }));

                dispatch(set_bms_data_types_from_ops_server(dataTypes));
            }
        }
    } catch (e) {
        toast.error(`Method getBMSTypesFromOpsServer \n ${e}`, toastOptions);
    }
};

// Создание сервера
export const addBMSServer =
    ({ data }) =>
    async dispatch => {
        const username = store.getState().login.username;
        const auth_key_calendar = store.getState().login.auth_key_calendar;

        try {
            const response = await bmsAPI.addBMSServer.fetch(username, auth_key_calendar, data);

            if (response.status === 200) {
                const res = await response.json();

                if (res.code === 0) {
                    dispatch(add_bms_server({ ...data, id: res.data.id, cur_state: data.is_enabled ? 2 : 1 }));
                } else {
                    toast.error(res.msg, toastOptions);
                }
            }
        } catch (e) {
            toast.error(`Method addBMSServer \n ${e}`, toastOptions);
        }
    };

// Редактирование сервера
export const editBMSServer =
    ({ data }) =>
    async dispatch => {
        const username = store.getState().login.username;
        const auth_key_calendar = store.getState().login.auth_key_calendar;

        try {
            const response = await bmsAPI.editBMSServer.fetch(username, auth_key_calendar, data);

            if (response.status === 200) {
                const res = await response.json();

                if (res.code === 0) {
                    dispatch(edit_bms_server({ ...data, cur_state: data.is_enabled ? 2 : 1 }));
                } else {
                    toast.error(res.msg, toastOptions);
                }
            }
        } catch (e) {
            toast.error(`Method editBMSServer \n ${e}`, toastOptions);
        }
    };

// Удаление сервера
export const removeBMSServer =
    ({ id }) =>
    async dispatch => {
        const username = store.getState().login.username;
        const auth_key_calendar = store.getState().login.auth_key_calendar;

        try {
            const response = await bmsAPI.removeBMSServer.fetch(username, auth_key_calendar, id);

            if (response.status === 200) {
                const res = await response.json();

                if (res.code === 0) {
                    dispatch(remove_bms_server(id));
                } else {
                    toast.error(res.msg, toastOptions);
                }
            }
        } catch (e) {
            toast.error(`Method removeBMSServer \n ${e}`, toastOptions);
        }
    };

export const server_error = state => state.bmsServers.bms_server_error;
export const bms_servers_list = state => state.bmsServers.bms_servers_list;
export const bms_servers_state = state => state.bmsServers.bms_servers_state;
export const servers_fetching = state => state.bmsServers.bms_servers_fetching;
export const bms_error_servers_arr = state => state.bmsServers.bms_error_servers_arr;
export const bmsDataTypeTimeIntervalsState = state => state.bmsServers.bms_data_type_time_intervals;
export const bmsDataTypesFromOpsServerState = state => state.bmsServers.bms_data_types_from_ops_server;
export const bmsAlarmsState = state => state.bmsServers.bmsAlarms;

export default bmsSliceServices.reducer;
